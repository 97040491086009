<template>
     <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-9 px-0">
                  <div class="col-5 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('goodwemonitoring.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    <vs-select v-if="conditions" v-model="searchCondition" :options="conditions" :placeholder="$t('goodwemonitoring.grids.placeholder.search_conditions')" label="name" :reduce="conditions => conditions.custom_filter_id"/>
                  </div>
                  <b-button
                    variant="secondary"
                    class="mr-3 ml-3 pr-2" @click.prevent="removeFilters">
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary" @click.prevent="searchGrids">
                  {{ $t("button.filter") }}   
                  </b-button>

              </div>
              <div class="card-toolbar">
                <span class="btn btn-success">
                  <span class="svg-icon svg-icon-md svg-icon-white">
                    <inline-svg src="/media/svg/icons/General/Update.svg" />
                  </span>
                  {{ $t("goodwemonitoring.button.sync") }}
                </span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table class="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr class="text-left text-uppercase">
                <th width="85">{{ $t("goodwemonitoring.grids.label.action") }}</th>
                <th>{{ $t("goodwemonitoring.grids.label.serial_number") }}</th>
                <th>{{ $t("goodwemonitoring.grids.label.model_type") }}</th>
                <th>{{ $t("goodwemonitoring.grids.label.status") }}</th>
                <th>{{ $t("goodwemonitoring.grids.label.capacity") }}</th>
                <th>{{ $t("goodwemonitoring.grids.label.total_generation") }}</th>
                <th width="100">{{ $t("goodwemonitoring.grids.label.arm_version") }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="(grid, index) in grids.data" :key="index">
                  <td class="pl-0">
                    <router-link
                      :to="{ name: 'goodwemonitoring_grids_detail', params: { id: grid.invertersn }}"
                      class="btn btn-icon btn-light btn-hover-primary  btn-sm"
                      >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/General/Search.svg" />
                      </span>
                    </router-link>
                    <router-link
                      :to="{ name: 'goodwemonitoring_plant_detail', params: { id: grid.plant_id }}"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
                      v-b-tooltip.right='$t("goodwemonitoring.meta.plant_detail")'
                      >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/Code/Git4.svg" />
                      </span>
                    </router-link>
                  </td>
                  
                  <td>
                    <span class="font-weight-bold">{{ grid.invertersn }}</span>
                  </td>

                  <td>
                    <span v-if="grid.model_type">{{ grid.model_type }}</span>
                    <span v-else class="text-muted">-</span> 
                  </td>
                
                  <td>
                    <b-badge v-if="grid.status == 1" variant="success">{{ $t("goodwemonitoring.label.generating") }}</b-badge>
                    <b-badge v-if="grid.status == -1" variant="danger">{{ $t("goodwemonitoring.label.offline") }}</b-badge>
                    <b-badge v-if="grid.status == 0" variant="warning">{{ $t("goodwemonitoring.label.waiting") }}</b-badge>
                    <b-badge v-if="grid.status == 2" variant="danger">{{ $t("goodwemonitoring.label.fault") }}</b-badge>
                    <b-badge v-if="grid.status == null" variant="secondary" class="px-7">-</b-badge>
                  </td>

                  <td>
                    <span v-if="grid.capacity" class="text-dark-75 font-weight-bolder">{{ grid.capacity.toFixed(2) }} kW </span>
                    <span v-else class="text-muted">-</span>
                  </td>
                  
                  <td>
                    <span class="" v-if="grid.total_generation">{{ grid.total_generation }} kW </span>
                    <span v-else class="text-muted">-</span>
                  </td>

                  <td class="text-center">
                    <span v-if="grid.arm_version">{{ grid.arm_version }}</span>
                    <span v-else class="text-muted">-</span>
                  </td>

                </tr>
            </tbody>
          </table>
          <Pagination :data="grids.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      loading: false,
      grids: null,
      conditions: null,
      searchCondition: null,
      page: 1,
      search: "",
    };
  },
  components: {
    Pagination
  },
  beforeMount() {
    // this.checkLastSearch();
    this.getGrids();
    this.getConditions();
  },
    metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.grids_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.devices"), subtitle: this.$t("goodwemonitoring.meta.grids_list") }]);
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
    gridSearch: {
      get () {
        return this.$store.state.search.gridSearch;
      },
      set (value) {
        this.$store.commit('setGridSearch', value);
      }
    },
  },
  methods: {
    getGrids() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/goodwe-monitoring/grids?page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search + "&custom_filter=" + this.searchCondition)
        .then((response) => {
          this.grids = response.data;
          this.loading = false;
        });
    },
    getConditions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/goodwe-monitoring/custom-filters?page=1&perpage=1000&search=&type=GRID")
        .then((response) => {
          this.conditions = response.data.data;
          this.loading = false;
        });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getGrids();
    },
    searchGrids(){
      this.page = 1;
      this.getGrids(); 
      this.gridSearch = {
        search: this.search
      } 
    },
    removeFilters(){
      this.page = 1;
      this.search = "";
      this.searchCondition = null;
      this.gridSearch = {
        search: this.search
      };
      this.getGrids(); 
    },
    checkLastSearch(){
      let lastSearch = this.$store.state.search.gridSearch;
      this.search = lastSearch.search;
    }
  }
};
</script>
